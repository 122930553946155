import styled from 'styled-components';
import { Button, Card, CARD_VARIANTS, Heading } from '@puregym/ui';
import { ImageBlock } from '~/blocks';

const StyledCard = styled(Card)`
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;

  ${Heading}, a:not(${Button}) {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const BlogPostLinkBlock = ({
  data: { name, url, primaryPostImage, primaryCategoryImage },
}) => (
  <StyledCard variant={CARD_VARIANTS.PLAIN} as={url ? 'a' : 'div'} href={url}>
    {(primaryPostImage || primaryCategoryImage) && (
      <ImageBlock
        data={{
          content: primaryPostImage || primaryCategoryImage,
          settings: primaryPostImage && {
            lazyLoad: primaryPostImage?.lazyLoad,
          },
        }}
      />
    )}
    <b>{name}</b>
  </StyledCard>
);

export { BlogPostLinkBlock };
